<template>
    <section class="anuncios-cartelera">
        <cargando v-if="cargando" />
        <div class="row mx-0">
            <!-- listado categorias -->
            <div class="col-12 col-sm-12 col-md-4 col-lg-3">
                <!-- title -->
                <div class="row">
                    <div class="col-12">
                        <p class="f-20 f-500 mb-4"> Categorías</p>
                    </div>
                </div>
                <!-- categorias -->
                <div class="row overflow-auto custom-scroll" style="height:calc(100vh - 200px);overflow-x: hidden !important;">
                    <div class="col-auto">
                        <el-checkbox v-model="checkAll" class="el-checkbox-negro" @change="seleccionarTodos">
                            Todas las categorías
                        </el-checkbox>
                        <div style="margin: 15px 0;" />
                        <el-checkbox-group v-model="categoriasIds" class="el-checkbox-negro" @change="listarCartelerasCategorias">
                            <el-checkbox v-for="(categoria,i) in categorias" :key="i" class="w-100 mb-3 f-300" :label="categoria.id">
                                <div class="d-middle">
                                    <div class="like-img rounded-circle d-middle-center" :style="`background: ${categoria.color}`">
                                        <i :class="`f-16 ${categoria.icono}`" />
                                    </div>
                                    <p class="ml-2"> {{ categoria.nombre }}</p>
                                </div>
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
            </div>
            <!-- contenido -->
            <div class="col-12 col-sm-12 col-md-12 col-lg-9">
                <!-- title -->
                <div class="row mb-4">
                    <div class="col-12 col-lg-auto">
                        <p class="f-20 f-500">Cartelera de anuncios</p>
                    </div>
                    <div class="col ml-auto my-auto">
                        <div class="row justify-content-lg-end">
                            <div class="col-auto mx-2">
                                <div class="row bg-f5 border br-8 indicadores">
                                    <div class="col-auto my-auto">
                                        <p class="f-17 f-500"> 
                                            {{ total }} 
                                            <span class="f-100 pl-2 f-13 text-muted">Total</span>
                                        </p>
                                    </div>
                                    <div class="col pr-0">
                                        <div class="indicador1 d-middle-center">
                                            <i class="icon-ad op-05" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto">
                        <el-input v-model="search" placeholder="Buscar" prefix-icon="el-icon-search" size="small" @change="listarCarteleras" />
                    </div>
                </div>
                <!-- listado solicitudes -->
                <div class="row overflow-auto custom-scroll" style="height: calc(100vh - 220px);">
                    <div v-for="(cartelera,i) in carteleras" :key="i" class="col-12 col-sm-6 col-md-5 col-lg-4 mb-2">
                        <anuncio :cartelera="cartelera" />
                        <!-- <card-anuncio /> -->
                    </div>
                    <div class="col-12">
                        <infinite-loading @infinite="listarCarteleras">
                            <div slot="spinner">
                                Consultando...
                            </div>
                            <div slot="no-more">
                                No hay más anuncios
                            </div>
                            <div slot="no-results" />
                        </infinite-loading>
                    </div>                                       
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Anuncio from '@/components/card/anuncio.vue'
import InfiniteLoading from 'vue-infinite-loading';
import Carteleras from '~/services/carteleras'
import Categorias from '~/services/carteleras/categorias'
export default {
    components:{
        Anuncio,
        InfiniteLoading
    },
    data(){
        return{
            inputSearch:'',
            checked: false,
            search:'',
            loading: false,
            categoriasIds: [],
            categorias:[],
            carteleras:[],
            total:0,
            page: 1,
            checkAll: false,
            checkedCities: [],
            cityOptions: [],
            cargando : false
        }
    },
    watch: {
        search(){
            this.page = 1
            this.carteleras = []
        },
        categoriasIds(){
            this.page = 1
            this.carteleras = []  
        }
    },
    created(){
        this.listarCategorias()
    },
    methods:{
        verSolicitud(){
            this.$router.push({ name:'anuncios.cartelera.ver' })
        },
        async listarCategorias(){
            try {
                this.cargando = true
                let params={
                    caso: 1
                }
                const {data} = await Categorias.listar(params)
                this.categorias = data.data
            } catch (e){
                this.errorCatch(e)
            } finally {
                this.cargando = false
            }
        },
        async listarCarteleras($state){
            try {
                const state = $state
                this.cargando = true

                let params={
                    caso : 1,
                    ids_categoria : this.categoriasIds,
                    mosaico : 1,
                    search : this.search,
                    page: this.page
                }
                const {data} = await Carteleras.listar(params)
                this.total = data.data.total

                if(data.data.data.length > 0){
                    let that = this
                    data.data.data.forEach(function(item,indice){
                        const exist = that.carteleras.filter(e => e.id === item.id)
                        if (exist.length < 1){
                            that.carteleras.push(item)
                        }
                    })                  
                    this.page += 1;
                    if(typeof state !== 'undefined'){
                        state.loaded();
                    }
                }else{
                    if(typeof state !== 'undefined'){
                        state.complete();
                    }
                }
            } catch (e){
                this.errorCatch(e)
            }   finally {
                this.cargando = false
            }
        },
        seleccionarTodos(val){
            if (val == true){
                this.categoriasIds = this.categorias.map(({ id }) => id)
                this.page = 1
            }else{
                this.page = 1
                this.categoriasIds = []
            }
            this.listarCarteleras()
        },
        async listarCartelerasCategorias(){
            const busqueda = async() => {
                this.listarCarteleras()
            }
            await this.delay(busqueda)
        }
    }
}
</script>
<style lang="scss" scoped>
.anuncios-cartelera{
    .card-solicitud{
        max-width: 304px;
        border-radius: 8px;
        position: relative;
        cursor: pointer;
        .container-img{
            height: 182px;
            overflow: hidden;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
        .container-info{
            min-height: 141px;
            // height: 141px;
            border-radius: 8px;
            border:1px solid #DBDBDB;
            position: relative;
            top: -8px;
            background: white;
            .categoria{
                min-height: 22px;
            }
            .tipo-user{
                min-width: 32px;
                max-width: 32px;
                max-height: 32px;
                min-height: 32px;
            }
        }
    }
}
</style>